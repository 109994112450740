$total: 200;
$time: 10s;


html, body, .wrap {
  height: 100%;
}

body.spaceTriangeles {
  background: black;
  background-image: radial-gradient(circle at center, white 0%, #222 10%, black 60%);
  overflow: hidden;


.wrap {
  transform-style: preserve-3d;
  perspective: 800px;
}

.tri {
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

@for $i from 1 through $total {
  $size: random(50) * 1px;
  $rotate: random(360) * 1deg;
  .tri:nth-child(#{$i}){
    border-top: $size solid hsla(random(360), 100%, 50%, 1);
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    margin-left: -$size/2;
    margin-top: -$size/2;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transform: rotate($rotate) translate3d(0,0,-1500px) scale(0);
    animation: anim#{$i} $time infinite linear;
    animation-delay: $i * -($time/$total);
    opacity: 0;
  }

  @keyframes anim#{$i}{
    0% {
      opacity: 1;
      transform: rotate($rotate * 1.5) translate3d(random(1000) * 1px, random(1000) * 1px,1000px) scale(1);
    }
  }
}
}


body.treeTrip {
  .mainContainer {
    height: 100%;
    /* max-height: 600px; */
    width: 1000px;
    background-color: hsla(200,40%,30%,.4);
    background-image:
            url('https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png'),
            url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),
            url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
            url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
            url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');
    background-repeat: repeat-x;
    background-position:
            0 20%,
            0 100%,
            0 50%,
            0 100%,
            0 0;
    background-size:
            2500px,
            800px,
            500px 200px,
            1000px,
            400px 260px;
    animation: 50s para infinite linear;
  }

  @keyframes para {
    100% {
      background-position:
              -5000px 20%,
              -800px 95%,
              500px 50%,
              1000px 100%,
              400px 0;
    }
  }
}


body.asciArt{

  background: #222222;
  .morph-section {
    width: 400px;
    height: 400px;

    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -200px;
    margin-left: -200px;
    font-family: monospace;
    color: #fff;
  }

  .info {
    font-family: monospace;
    position: absolute;
    line-height: 20px;
    font-size: 14px;
    left: 20px;
    bottom: 20px;
    color: #fff;
  }

  a {
    color: #f9f9f9;
  }
}